import tree from 'state';
import { get, pick, identity, pickBy } from 'lodash';
import { onboardingValueExtractor } from 'utility/dynamicFormHelpers';
import * as onboardingEffects from 'state/onboarding/effects';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import * as opportunityEffects from 'state/opportunities/effects';
import { downloadFileFromBlob } from 'utility/downloadFile';
import * as supplyEffects from 'state/supplyInterests/effects';
import * as effects from './effects';
import { defaultActiveCustomer } from './state';
import { generateCustomer } from './transformer_customers';
import { generateCustomerBusinessInfo } from './transformer_customers_businessInfo';

import { generateFormFlexData } from '../flexEntities/actions';

export const customersCursor = tree.select(['customers']);

export async function getEngagements({ pagination, filters, sorting } = {}) {
  await asyncTreeRequester({
    func: effects.getCustomerEngagements.bind(this),
    pagination,
    filters,
    sorting,
    cursor: customersCursor,
    path: ['engagements'],
  });
}

export async function exportUsersList({ filters, sorting } = {}) {
  return asyncTreeRequester({
    func: effects.exportUsersList.bind(this),
    filters,
    sorting,
    cursor: customersCursor,
    path: ['list', 'export'],
    onSuccess: (blob) => {
      downloadFileFromBlob(blob, 'users-export.xlsx');
    },
  });
}

export async function getMyCustomers({ pagination, filters, sorting } = {}) {
  return asyncTreeRequester({
    func: effects.getCustomers.bind(this),
    pagination,
    filters,
    sorting,
    cursor: customersCursor,
    path: ['currentCustomers'],
  });
}

export async function getCustomersWithLastEvent({
  pagination,
  filters,
  sorting,
} = {}) {
  return asyncTreeRequester({
    func: effects.getCustomersWithLastEvent.bind(this),
    pagination,
    filters,
    sorting,
    cursor: customersCursor,
    path: ['currentCustomers'],
  });
}

export async function getUserMatchesPreview({
  contentType,
  objectId,
  matchTypes,
}) {
  return asyncTreeRequester({
    func: effects.getItemUserMatches.bind(this, {
      contentType,
      objectId,
      matchTypes,
    }),
    cursor: customersCursor,
    path: ['customersPreview'],
  });
}

export async function getItemUserMatches({
  contentType,
  objectId,
  matchTypes,
}) {
  return asyncTreeRequester({
    func: effects.getItemUserMatches.bind(this, {
      contentType,
      objectId,
      matchTypes,
    }),
    cursor: customersCursor,
    path: ['currentCustomers'],
  });
}

export function setMatchesCustomers(customers) {
  customersCursor.merge(['currentCustomers'], customers);
  tree.commit();
}

export async function setMatchesFilters(filters) {
  customersCursor.set(['currentCustomers', 'filters'], filters);
  tree.commit();
}

export async function setMatchesSort({ field, direction }) {
  customersCursor.set(['currentCustomers', 'result', 'sort'], {
    sort: field,
    direction,
  });
  tree.commit();
}

export async function setMatchesPagination(pagination) {
  customersCursor.merge(['currentCustomers', 'result'], pagination);
  customersCursor.merge(
    ['currentCustomers', 'result', 'pagination'],
    pagination
  );
  tree.commit();
}

export function resetCustomerCursors() {
  customersCursor.set(['activeCustomer'], defaultActiveCustomer());
}

export async function fetchActiveCustomerSettings({ userId }) {
  return asyncTreeRequester({
    func: effects.getCustomerSettings.bind(this, userId),
    cursor: customersCursor,
    path: ['activeCustomer', 'customerSettings'],
  });
}

export async function fetchActiveCustomerBusinessInfo({ hasMeta, userId }) {
  const customer = customersCursor.get(['activeCustomer']);
  const currentHasMeta =
    hasMeta || get(customer, 'customerSettings.result.has_user_meta');
  let customerBusinessInfo = {};
  if (currentHasMeta) {
    customerBusinessInfo = await asyncTreeRequester({
      func: onboardingEffects.getBusinessInfoDetails.bind(this, { userId }),
      cursor: customersCursor,
      path: ['activeCustomer', 'customerBusinessInfo'],
    });
  }

  const customerBusinessInfoFormData = generateCustomerBusinessInfo({
    seedData: customerBusinessInfo,
  });
  customerBusinessInfoFormData.user = userId;
  customersCursor.set(
    ['activeCustomer', 'customerBusinessInfo', 'formData'],
    customerBusinessInfoFormData
  );

  return customerBusinessInfo;
}

export async function fetchActiveCustomerData(userId) {
  const customerSettings = await fetchActiveCustomerSettings({ userId });

  await asyncTreeRequester({
    func: effects.getCustomerDetails.bind(this, userId),
    cursor: customersCursor,
    path: ['activeCustomer', 'customerDetails'],
  });

  let customerBusinessInfo = fetchActiveCustomerBusinessInfo({
    userId,
    hasMeta: customerSettings.has_user_meta,
  });
  customerBusinessInfo = await customerBusinessInfo;

  return { ...customerBusinessInfo };
}

export async function setActiveCustomer(customer, { reset } = {}) {
  if (reset !== false) {
    resetCustomerCursors();
  }
  const userId = customer ? customer.user || customer.pk || customer.id : null;
  let customerBusinessInfo = {};
  let currentCustomer = customer;
  if (customer) {
    customerBusinessInfo = await fetchActiveCustomerData(userId);
    currentCustomer = { ...customer, ...customerBusinessInfo };
  }
  const customerFormData = generateCustomer({
    seedData: currentCustomer,
  });

  customerFormData.user = userId;
  customersCursor.set(['activeCustomer', 'customerFormData'], customerFormData);
  tree.commit();

  const customerFlexFormData = await generateFormFlexData({
    seedData: customer,
  });
  customersCursor.set(
    ['activeCustomer', 'customerFlexFormData'],
    customerFlexFormData
  );
}

export async function fetchActiveCustomerWishlist(userId) {
  await asyncTreeRequester({
    func: effects.getCustomerProductWishlist.bind(this, { userId }),
    cursor: customersCursor,
    path: ['activeCustomer', 'wishlist'],
  });
}

export async function getCustomerInternalRelationships(userId) {
  return asyncTreeRequester({
    func: effects.getCustomerInternalRelationships.bind(this, userId),
    cursor: customersCursor,
    path: ['activeCustomer', 'internalRelationships'],
  });
}

// FORM MANAGMENT OF CUSTOMERS BELOW

export async function setCustomerInfo(basePath, category, type, data) {
  const currentBasePath = basePath || ['activeCustomer', 'customerFormData'];
  const path = [...currentBasePath, category, 'fields', type];
  const item = customersCursor.get(path);
  const currentData = data;
  currentData.dirty = true;
  currentData.valid = item.validation
    ? item.validation(currentData.value, item.required)
    : true;
  customersCursor.merge(path, currentData);
  customersCursor.set([category, 'dirty'], true);
  tree.commit();
}

export async function submitCustomer() {
  const customer = customersCursor.get(['activeCustomer', 'customerFormData']);
  const currentUserId = customer.user;
  const values = onboardingValueExtractor(pick(customer, 'customer'));
  const customerDetails = await asyncTreeRequester({
    func: currentUserId
      ? effects.updateCustomer.bind(this, currentUserId, values)
      : effects.submitCustomer.bind(this, values),
    cursor: customersCursor,
    isSaving: true,
    path: ['activeCustomer', 'customerDetails'],
    transformer: ({ result, cursor = {} }) => {
      const original = cursor.result || {};
      return { ...original, ...result };
    },
  });

  return customerDetails;
}

export async function updateCustomerCampaignRecipient(userId, data) {
  return asyncTreeRequester({
    func: effects.updateCustomer.bind(this, userId, data),
    cursor: customersCursor,
    path: ['activeCustomer', 'customerDetails'],
    transformer: ({ result, cursor = {} }) => {
      const original = cursor.result || {};
      return { ...original, campaign_recipient: result.campaign_recipient };
    },
  });
}

export async function submitCustomerBusinessInfo({ user }) {
  const customerBusinessInfo = customersCursor.get([
    'activeCustomer',
    'customerBusinessInfo',
    'formData',
  ]);
  const customer = customersCursor.get('activeCustomer');
  const update = get(customer, 'customerSettings.result.has_user_meta');
  const values = onboardingValueExtractor(customerBusinessInfo);
  const data = pickBy({ ...values, user: user.id }, identity);

  const result = await asyncTreeRequester({
    func: update
      ? onboardingEffects.updateBusinessInfoDetails.bind(this, data, {
          userId: user.id,
        })
      : onboardingEffects.saveBusinessInfoDetails.bind(this, data),
    isSaving: true,
    cursor: customersCursor,
    path: ['activeCustomer', 'customerBusinessInfo'],
  });
  fetchActiveCustomerSettings({ userId: user.id });

  return result;
}

export async function getCustomerOpportunities({
  pagination,
  filters,
  sorting,
} = {}) {
  await asyncTreeRequester({
    func: opportunityEffects.getOpportunities.bind(this, {}),
    pagination,
    filters,
    sorting,
    cursor: customersCursor,
    path: ['activeCustomer', 'currentOpportunities'],
  });
}

export async function getCustomerSupplyInterests({
  pagination,
  filters,
  sorting,
} = {}) {
  await asyncTreeRequester({
    func: supplyEffects.getSupplyInterestsItems.bind(this, {}),
    pagination,
    filters,
    sorting,
    cursor: customersCursor,
    path: ['activeCustomer', 'supplyInterests'],
  });
}

// CUSTOMER ENRICHMENT
export async function enrichCustomer(customerId) {
  const response = await asyncTreeRequester({
    func: effects.enrichCustomer.bind(this, customerId),
    cursor: customersCursor,
    path: ['activeCustomer', 'enrichment'],
  });
  return response;
}

export async function getEnrichedCustomer(customerId) {
  const response = await asyncTreeRequester({
    func: effects.getEnrichedCustomer.bind(this, customerId),
    cursor: customersCursor,
    path: ['activeCustomer', 'enrichment'],
  });
  return response;
}

// FORM MANAGEMENT OF INTERNAL RELATIONSHIPS
export async function createInternalRelationship({
  user,
  relatedUser,
  description,
  type,
}) {
  try {
    const result = await effects.createInternalRelationship({
      user,
      relatedUser,
      description,
      type,
    });
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function updateInternalRelationship(
  relationshipId,
  { user, relatedUser, description, type }
) {
  try {
    const result = await effects.updateInternalRelationship({
      relationshipId,
      user,
      relatedUser,
      description,
      type,
    });
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function deleteInternalRelationship(relationshipId) {
  try {
    const result = await effects.deleteInternalRelationship(relationshipId);
    return result;
  } catch (err) {
    return err.message;
  }
}

// MANAGEMENT

export function patchCustomerDetails(data) {
  try {
    customersCursor.merge(
      ['activeCustomer', 'customerDetails', 'result'],
      data
    );
    return true;
  } catch (err) {
    return err.message;
  }
}

export async function mergeCustomers(data) {
  try {
    const result = await asyncTreeRequester({
      func: effects.mergeCustomers.bind(this, data),
      handleResult: true,
    });
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function deleteCustomers(data) {
  try {
    const result = await asyncTreeRequester({
      func: effects.deleteCustomers.bind(this, data),
      handleResult: true,
    });
    return result;
  } catch (err) {
    return err.message;
  }
}
