import React, { useState, useCallback } from 'react';
import InPageOverlay from 'components/InPageOverlay/InPageOverlay';
import {
  isFormValid,
  onboardingValueExtractor,
} from 'utility/dynamicFormHelpers';
import DropZoneFileManager from 'components/DropZone/DropZoneFileManager';
import { TabList, Tab } from '@zendeskgarden/react-tabs';
import { trackClick } from 'utility/analytics';
import { Button } from 'theme/Button';
import Icon from 'components/Icon/Icon';
import { useBranch } from 'baobab-react/hooks';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { MD, XL } from '@zendeskgarden/react-typography';
import { get, pick, includes } from 'lodash';
import { closeSidebar } from 'state/sidebars/actions';
import { variables } from 'theme/variables';
import MultiTypeForm from 'components/MultiTypeForm/MultiTypeForm';
import {
  setCustomerInfo,
  submitCustomerBusinessInfo,
  submitCustomer,
  setActiveCustomer,
  getMyCustomers,
} from 'state/customers/actions';
import { AddNewButton } from 'components/MultiTypeForm/components/LineItemRows/LineItemRows';
import { AddColumnFormWrapper } from 'routes/Customers/orm/tableComponents/AddTableColumn';
import {
  createFlexEntityJson,
  addNewFieldToFlexDataMultitypeForm,
} from 'state/flexEntities/actions';
import { PropTypes } from 'prop-types';
import {
  SidebarFilterTabs,
  SidebarFilterHeader,
  SidebarFilterBody,
  SidebarContentWrapper,
  SidebarFooterWrapper,
} from '../../GlobalSideBar.styles';

const { spacing, custom_lime: customLime } = variables;

function CustomerApprovalSidebar({
  options,
  tableType,
  onSetCloseWarn,
  onClickClose,
}) {
  const { showTabs, refetchUser, companyTab, onSubmit } = options;
  const [inPageOverlayViewOpen, setInPageOverlay] = useState(false);
  const {
    rfqMetadata,
    customerFlexFormData,
    activeCustomer,
    customerDetails,
    businessFormData,
    customerBusinessInfo,
  } = useBranch({
    activeCustomer: ['customers', 'activeCustomer', 'customerFormData'],
    customerDetails: ['customers', 'activeCustomer', 'customerDetails'],

    businessFormData: [
      'customers',
      'activeCustomer',
      'customerBusinessInfo',
      'formData',
    ],
    customerBusinessInfo: [
      'customers',
      'activeCustomer',
      'customerBusinessInfo',
    ],

    customerFlexFormData: [
      'customers',
      'activeCustomer',
      'customerFlexFormData',
    ],
    rfqMetadata: ['requestForQuote', 'meta', 'result'],
  });

  const [userTab, setUserTab] = useState(
    companyTab ? 'businessInfo' : 'register'
  );
  const [registeredUser, setRegisterSuccess] = useState(null);
  const [finalSuccess, setFinalSuccess] = useState(false);
  const [flexSavingStatus, setFlaxSavingStatus] = useState({});
  const [isMultipleFiles, setIsMultipleFiles] = useState();
  const [isNewCustomer, setIsNewCustomer] = useState(options.isNewCustomer);
  const registerFields = activeCustomer ? pick(activeCustomer, 'customer') : {};
  const busInfoFields = activeCustomer ? businessFormData : {};
  // TODO Fix spelling
  const flexFeilds = activeCustomer ? customerFlexFormData : {};
  let modalType;

  let activeFields;
  let basePath;
  switch (userTab) {
    case 'register':
      activeFields = registerFields;
      basePath = ['activeCustomer', 'customerFormData'];
      break;
    case 'businessInfo':
      activeFields = busInfoFields;
      basePath = ['activeCustomer', 'customerBusinessInfo', 'formData'];
      break;
    case 'flexFeilds':
      activeFields = flexFeilds;
      basePath = ['activeCustomer', 'customerFlexFormData'];
      break;
    default:
      activeFields = null;
      basePath = null;
      break;
  }

  const isSaving =
    get(customerBusinessInfo, 'isSaving') ||
    get(customerDetails, 'isSaving') ||
    flexSavingStatus.saving;
  const error =
    get(customerBusinessInfo, 'error') ||
    get(customerDetails, 'error') ||
    flexSavingStatus.error;

  let flexValid;
  if (userTab === 'flexFeilds') {
    flexValid = isFormValid(customerFlexFormData);
  }

  const contentType = get(
    rfqMetadata,
    `entity_schema_content_types.${tableType || 'Contact'}`
  );

  const onSetWarnSetting = useCallback(
    (warn) => {
      if (onSetCloseWarn) {
        onSetCloseWarn(warn);
      }
    },
    [onSetCloseWarn]
  );

  async function onSubmitCustomer(e) {
    trackClick('onSubmitCustomer', 'Submit', 'CustomerApprovalSidebar');
    e.preventDefault();

    if (userTab === 'register') {
      const customer = await submitCustomer({ refetchUser });
      if (!customer.error) {
        if (!includes(window.location.href, '/profile')) {
          setRegisterSuccess(customer);
          getMyCustomers();
          closeSidebar();
        } else {
          onSetWarnSetting(false);
          setActiveCustomer(customer);
          setRegisterSuccess(customer);
          setUserTab('businessInfo');
        }
      }
    } else if (userTab === 'businessInfo') {
      try {
        const businessInfo = await submitCustomerBusinessInfo({
          user: registeredUser || { id: activeCustomer.user },
        });
        if (!businessInfo.error) {
          onSetWarnSetting(false);
        }
        if (onSubmit) {
          closeSidebar();
          onSubmit({
            ...(registeredUser || {}),
            ...businessInfo,
          });
        } else {
          setFinalSuccess();
        }
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    } else if (userTab === 'flexFeilds') {
      try {
        setFlaxSavingStatus({ saving: true });
        const value = onboardingValueExtractor(customerFlexFormData);
        const r = await createFlexEntityJson({
          value,
          id: customerDetails.result.contact_id || activeCustomer.user,
          contentType,
        });
        if (!r.error) {
          onSetWarnSetting(false);
          setIsNewCustomer(false);
        }
        setFlaxSavingStatus({ saving: false, error: r.error });
      } catch (err) {
        alert(err.message);
      }
    }
  }

  const onSetChange = useCallback(
    (baseP, category, type, data) => {
      onSetWarnSetting(true);
      setCustomerInfo(baseP, category, type, data);
    },
    [onSetWarnSetting]
  );

  if (isMultipleFiles > 0) {
    modalType = '';
  } else {
    modalType = 'upload';
  }

  return (
    <SidebarContentWrapper>
      <SidebarFilterHeader padding={`${spacing}`}>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <Icon icon="icon-profile" fontSize="18px" color="#fff" />
            <MD scaled paddingHorizontal bold color="#fff">
              {includes(window.location.href, '/profile')
                ? `Edit Contact Information`
                : `Contact Information`}
            </MD>
          </Flex>
          <IconButton
            onClick={onClickClose}
            disableBackground
            alignCenter
            justifyEnd
            size="large"
            aria-label="closeSidebar"
          >
            <Icon button icon="icon-close" fontSize="13px" color="#fff" />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>
      {finalSuccess ? (
        <SidebarFilterBody>
          <Flex column alignCenter justifyCenter style={{ marginTop: spacing }}>
            <Icon
              color={customLime}
              icon="icon-check-outline"
              fontSize="57px"
            />
            <XL paddingVerticalLg bold>
              Successfully Added Contact.
            </XL>
            <Button primary onClick={closeSidebar}>
              Continue
            </Button>
          </Flex>
        </SidebarFilterBody>
      ) : (
        <>
          {showTabs ? (
            <SidebarFilterTabs>
              <TabList className="tabs no-border">
                <Tab
                  className="tab"
                  slate
                  onClick={() => {
                    setUserTab('register');
                  }}
                  selected={userTab === 'register'}
                >
                  Contact
                </Tab>
                <Tab
                  className="tab"
                  slate
                  onClick={() => {
                    setUserTab('businessInfo');
                  }}
                  selected={userTab === 'businessInfo'}
                >
                  Business Information
                </Tab>

                <Tab
                  className="tab"
                  slate
                  onClick={() => {
                    setUserTab('flexFeilds');
                  }}
                  selected={userTab === 'flexFeilds'}
                >
                  Custom Fields
                </Tab>
              </TabList>
            </SidebarFilterTabs>
          ) : null}

          <SidebarFilterBody>
            <form onSubmit={(e) => onSubmitCustomer(e)}>
              <MultiTypeForm
                contentType={rfqMetadata?.tag_content_types?.AuthUser}
                formName="customerApproval"
                error={error}
                // TODO: usual changes have not fixed this will come back to fix
                // eslint-disable-next-line react/jsx-no-bind
                setData={onSetChange.bind(this, basePath)}
                data={activeFields}
              />
              {isNewCustomer === false ? (
                <div>
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: '12px',
                      textAlign: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    Upload User Image
                  </div>
                  <DropZoneFileManager
                    type={modalType}
                    hasExternalSubmit
                    uploadOnDrop={false}
                    multiple={false}
                    isImage
                    onRemoveFile={() => {
                      setIsMultipleFiles(isMultipleFiles - 1);
                    }}
                    showUploadAndRender
                    objectId={customerDetails?.result?.id}
                    contentType={rfqMetadata?.tag_content_types?.AuthUser}
                    disableDelete={false}
                    onRemo
                    setFileCount={setIsMultipleFiles}
                  />
                </div>
              ) : null}
              {userTab === 'flexFeilds' ? (
                <AddNewButton
                  style={{ marginTop: '20px', marginBottom: '40px' }}
                  onClick={() => setInPageOverlay(true)}
                  title="Add New Field"
                />
              ) : null}
            </form>
          </SidebarFilterBody>

          <SidebarFooterWrapper>
            <Button
              disabled={userTab === 'flexFeilds' ? !flexValid : false}
              error={error}
              buttonStatus
              loading={isSaving}
              style={{ width: '100%' }}
              primary
              onClick={(e) => onSubmitCustomer(e)}
            >
              Save Contact
            </Button>
          </SidebarFooterWrapper>
        </>
      )}
      <InPageOverlay
        wrapperStyle={{ zIndex: 9999, position: 'relative' }}
        disableScroll
        portal
        onClose={() => setInPageOverlay(false)}
        open={inPageOverlayViewOpen}
      >
        <div style={{ width: '100%' }}>
          <AddColumnFormWrapper
            contentType={contentType}
            onCommit={(newField) => {
              addNewFieldToFlexDataMultitypeForm(newField);
            }}
            onSuccess={() => {
              setInPageOverlay(false);
            }}
          />
        </div>
      </InPageOverlay>
    </SidebarContentWrapper>
  );
}

CustomerApprovalSidebar.propTypes = {
  options: PropTypes.shape({
    showTabs: PropTypes.bool,
    refetchUser: PropTypes.bool,
    companyTab: PropTypes.string,
    onSubmit: PropTypes.func,
    isNewCustomer: PropTypes.bool,
  }),
  tableType: PropTypes.string,
  onSetCloseWarn: PropTypes.func,
  onClickClose: PropTypes.func,
};

export default CustomerApprovalSidebar;
